import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import logoImg from '../img/logo.png'
import iconImg from '../img/icon.png'
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import StoreIcon from '@mui/icons-material/Store';
import { Instagram, YouTube } from '@mui/icons-material';


function Sidebar() {
    return (
        <>
            <aside className="sidebar">
                <div className='sidebar-line'>
                    <div className="sidebar-logo d-flex align-items-center justify-content-between">
                        <a href="#sec" class="header-logo">
                            {/* <img src="assets/images/logo.svg" class="img-fluid light-logo" alt="logo" /> */}
                            <img src={logoImg} className='big-logo' height="50px" width="200px" alt='logo' />
                            <img src={iconImg} className='mini-logo' height="40px" width="40px" alt='logo' />
                        </a>
                        <div className="menu-bt-sidebar toggle-sidebar-btn">
                            <MenuOpenOutlinedIcon sx={{ color: "white" }} fontSize='large' />
                        </div>
                    </div>
                </div>
                <div className='sidebar-menu'>
                    <ul className="sidebar-menu-nav">
                        <li>
                            <a href="https://kronobit.org/" target='_blank' rel="noreferrer">
                                <DashboardIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Website</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://kronostake.com/" target='_blank' rel="noreferrer" className='active'>
                                <EmojiEventsIcon sx={{ color: "#079970" }} className='me-2' />
                                <span>Staking</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://kronoswap.com/?chain=kronobit" target='_blank' rel="noreferrer">
                                <SwapHorizIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Swap</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://kronostake.com/" target='_blank' rel="noreferrer">
                                <LayersOutlinedIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>KNB Stake</span>
                            </a>
                        </li>
                        
                    </ul>
                </div>
                <div className='sidebar-bottom'>
                    <h5 className='text-center text-white'>Join Us</h5>
                    <ul className='social-list'>
                        <li>
                            <a href="https://t.me/kronobit" target='_blank' rel="noreferrer">
                                <TelegramIcon sx={{ fontSize: 24 }} />
                            </a>
                        </li>
                        <li>
                            <a href="https://x.com/Kronobit_Knb" target='_blank' rel="noreferrer">
                                <XIcon sx={{ fontSize: 20 }} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@kronobit_networks" target='_blank' rel="noreferrer">
                                <YouTube sx={{ fontSize: 24 }} />
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>
        </>
    )
}
export default Sidebar