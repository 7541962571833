import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
  } from "@web3modal/ethereum";
  
  import { configureChains, createClient } from "wagmi";
  import { chains } from "./constant";
  
  
  const projectId = process.env.REACT_APP_PROJECT_ID
  // Wagmi client
  const { provider } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  
  export const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    provider,
  });
  
  // Web3Modal Ethereum Client
  export const ethereumClient = new EthereumClient(wagmiClient, chains);