import React from 'react'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Groups2Icon from '@mui/icons-material/Groups2';
import { formatPrice } from '../helper/functions';
import { SYMBOL } from '../helper/constant';
import swapIcon from '../img/swap.png';


export default function MyWallet({ accStats }) {
    return (
        <div className='col-sm-12 col-md-12 col-lg-4 mt-4 mt-xl-0'>
            <div className='card wallet-card'>

                <div className='card-body'>
                    <h5 className='mb-0 p-2'>My Wallet</h5>
                    <div className='card card-gray mb-2'>
                        <div className='card-body'>

                            <div className='d-flex align-items-center'>
                                <div className='icon-box'>
                                    <SwapHorizIcon sx={{ color: "#fff" }} fontSize="large" />
                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-yellow mb-1'>BNB Balance</h6>
                                    <h5 className='mb-0'>{accStats.bnbBalance ? formatPrice(accStats.bnbBalance, 5) : 0} BNB</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card card-gray mb-2'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <div className='icon-box'>
                                    <EmojiEventsIcon sx={{ color: "#fff" }} fontSize="large" />

                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-yellow mb-1'>{SYMBOL} Balance</h6>
                                    <h5 className='mb-0'>{accStats.balance ? formatPrice(accStats.balance, 10) : 0} {SYMBOL}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-gray mb-2'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <div className='icon-box'>
                                    <EmojiEventsIcon sx={{ color: "#fff" }} fontSize="large" />

                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-yellow mb-1'>Total Staked</h6>
                                    <h5 className='mb-0'>{accStats.totalStake ? formatPrice(accStats.totalStake, 10) : 0} {SYMBOL}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-gray mb-2'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <div className='icon-box'>
                                    <EmojiEventsIcon sx={{ color: "#fff" }} fontSize="large" />

                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-yellow mb-1'>Total Earnings</h6>
                                    <h5 className='mb-0'>{accStats.totalRewardEarn ? formatPrice(accStats.totalRewardEarn, 10) : 0} {SYMBOL}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
