import React, { useEffect, useState } from 'react';
import logoImg from '../img/icon.png';
import { formatPrice, getWeb3 } from '../helper/functions';
import {  DECIMALS, DEFAULT_CHAIN, SYMBOL, contract, daysTab } from '../helper/constant';
import stakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { getContract } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import Loading from './Loading';
import MyWallet from './MyWallet';
import TabButton from './TabButton';



export default function Stake({ commonStats, accStats, setUpdater, setTab, tab }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const { open } = useWeb3Modal()
    const [apy, setApy] = useState(daysTab[0].apy);
    const [amount, setAmount] = useState(0);
    const [durationIndex, setDurationIndex] = useState(0);
    let web3 = getWeb3();
    const [error_msg, setError_msg] = useState('');
    const [ereturn, setEreturn] = useState(0);
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const [endtime, setEndtime] = useState(new Date(parseInt(timeElapsed) + parseInt(daysTab[0].timestamp * 86400 * 1000)));
    const [loading, setLoading] = useState(false);
    const [dayId, setDayId] = useState(1);


    const handleApprove = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {

                    setLoading(true);

                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS, signer);
                    let amount1 = ethers.utils.parseEther('10000000000000').toString();

                    let tx = await tokenContract.approve(contract[DEFAULT_CHAIN].STAKE_ADDRESS, amount1, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleStake = async () => {
        setLoading(true);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {

                    if (parseFloat(accStats.balance) >= parseFloat(amount)) {

                        let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                        let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);
                        let stakeAmount = ethers.utils.parseUnits(amount.toString() , DECIMALS)
                        let lockupDuration = daysTab[dayId - 1].timestamp;
                        let tx = await stakeContract.deposit( lockupDuration ,stakeAmount.toString(), { 'from': address });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success 👍');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                            }
                        }, 5000);

                    }
                    else {
                        toast.error('you don\'t have enough balance !');
                        setLoading(false);
                    }

                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);


        if (isNaN(e.target.value)) {
            setError_msg('Please enter valid amount');

        }

        else if (parseFloat(e.target.value) === 0 || e.target.value === '') {
            setError_msg('Amount must be greater than zero');

        }
        else {
            setError_msg('');

        }
        return;
    }


    const handleChangeDay = (id) => {
        setDayId(id);
        setApy(daysTab[id - 1].apy)
        setEndtime(new Date(parseInt(timeElapsed) + parseInt(daysTab[id - 1].timestamp * 86400 * 1000)))
    }

    useEffect(() => {
        setEreturn(parseFloat(amount) + parseFloat((amount * daysTab[dayId - 1].apy / 100) / 365) * daysTab[dayId - 1].timestamp);
        // eslint-disable-next-line
    }, [amount, dayId])


    return (
        <div>
            <div className='row mb-4'>
                <div className='col-12 col-xl-8'>
                    <div className='d-flex justify-content-center row'>
                        <TabButton setTab={setTab} tab={tab} />
                        <div className='col-12 col-xl-9'>
                            <div className='row'>
                                <div className='col-sm-12 col-xl-12 col-lg-12 mb-2 mb-md-3 text-center'>
                                    {daysTab && daysTab.length > 0 ? (
                                        daysTab.map((link, key) => {
                                            return (
                                                <button type='button' onClick={(e) => { handleChangeDay(link.id) }} className={`btn ${link.id === dayId ? 'btn-warning active' : 'btn-warning-info bg-dark-1'} btn-amounts  my-2 mx-1 mb-md-0 stake-select-btn`}>
                                                    <div className="fs-21">{link.content}</div>
                                                    <p>{link.apy}% APY</p>
                                                </button>

                                            )
                                        })
                                    ) : (
                                        <Loading />
                                    )
                                    }
                                </div>
                                <div className='col-12 mt-1 mt-md-3  mb-3'>
                                    <h5 className='fs-21 mt-1 fw-normal text-white text-center'>
                                        Your Balance: {accStats.balance ? formatPrice(accStats.balance , 6) : 0 } KNB

                                    </h5>
                                    <div class="add-liquidity mt-3 mob-margin">
                                        <div class="content-stake">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="labels"><h6>{SYMBOL}</h6></div>
                                                <div class="labels1">
                                                    <input type="text" placeholder="Enter amount" value={amount} onChange={(e) => { handleChangeAmount(e) }} />
                                                </div>
                                                <div>
                                                    <img src={logoImg} class="icon-img" alt="icon" />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <h5 className='fs-21 mt-1 fw-normal mb-sm-5 text-danger text-center'><small>{error_msg}</small></h5>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-xl-9 mt-2 text-white d-flex justify-content-center'>
                            <div className='table-grid'>
                                <div>
                                    <div>Duration</div>
                                    <div>:</div>
                                    <div>{daysTab[dayId - 1].content}</div>
                                </div>
                                <div>
                                    <div>APY</div>
                                    <div>:</div>
                                    <div>{apy}%</div>
                                </div>
                                <div>
                                    <div>Stake Amount</div>
                                    <div>:</div>
                                    <div>{formatPrice(amount)} {SYMBOL}</div>
                                </div>
                                <div>
                                    <div>Estimated Return</div>
                                    <div>:</div>
                                    <div>{formatPrice(ereturn)}</div>
                                </div>
                                <div>
                                    <div>Start Date</div>
                                    <div>:</div>
                                    <div>{today.toUTCString()}</div>
                                </div>
                                <div>
                                    <div>End Date</div>
                                    <div>:</div>
                                    <div>{endtime.toUTCString()}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-12 col-md-12 text-center align-items-center mt-1'>
                        {
                            address && isConnected ? (
                                
                                    parseInt(accStats.isApprove) > parseFloat(amount) ? (
                                        <button className="btn btn-warning ms-4 btn-lg btn-full" disabled={loading} onClick={() => !loading ? handleStake() : null}>{loading ? 'Loading…' : 'Invest Now'}</button>
                                    ) : (
                                        <button className="btn btn-warning ms-4 btn-lg btn-full" disabled={loading} onClick={() => !loading ? handleApprove() : null}>{loading ? 'Loading…' : 'Approve'}</button>
                                    )
                            ) : (
                                <button className="btn btn-warning ms-4 btn-lg btn-full" onClick={() => open()} >Connect Wallet</button>
                            )
                        }
                    </div>

                </div>

                <MyWallet accStats={accStats} />


            </div>

        </div>
    )
}
