import React, { useEffect, useState } from 'react'
import { DECIMALS, DEFAULT_CHAIN, contract } from '../helper/constant';
import { formatPrice, getWeb3 } from '../helper/functions';
import stakeAbi from '../json/bscstake.json';
import { useAccount, useNetwork } from 'wagmi';
import { getContract } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useEthersSigner } from '../hooks/useEthersProvider';
import TabButton from './TabButton';
import MyWallet from './MyWallet';


export default function Unstake({ accStats, setUpdater, setTab, tab }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork()
    const signer = useEthersSigner()
    const [orders, setOrders] = useState([]);
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000))
    const [rewardcal, setRewardcal] = useState({ pendingReward: 0 });
    const web3 = getWeb3();
    const [cLoading,setCLoading] = useState([])
    const [tLoading,setTLoading] = useState([])


    const handleClaim = async (orderId, index) => {
        setCLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.withdraw(orderId, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                setUpdater(Math.random());
                                setCLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setCLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setCLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setCLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setCLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setCLoading(['', false]);
        }
    }

    const handleTerminate = async (index, orderId) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.emergencyWithdraw(orderId, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }

    useEffect(() => {
        const getOrder = async () => {
            try {
                let stakecontract = new web3.eth.Contract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

                const data_array = await stakecontract.methods.investorOrderIds(address).call();
                Promise.all(data_array.map(async (index) => {
                    const getdata = await stakecontract.methods.orders(index).call();
                    const pendingReward = await stakecontract.methods.pendingRewards(index).call();
                    const object = {
                        amount: getdata.amount.toString() / Math.pow(10, DECIMALS),
                        lockupDuration: getdata.lockupDuration.toString(),
                        returnPer: getdata.returnPer.toString(),
                        starttime: getdata.starttime.toString(),
                        endtime: getdata.endtime.toString(),
                        claimedReward: getdata.claimedReward.toString(),
                        claimed: getdata.claimed.toString(),
                        orderId: index,
                        pendingReward: pendingReward / Math.pow(10, DECIMALS)
                    }
                    return object;
                })).then((result) => {
                    setOrders(result);
                })
            } catch (err) {
                console.log(err);
            }
        };

        async function rewardCount() {
            try {
                let stakecontract = new web3.eth.Contract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
                const data_array = await stakecontract.methods.investorOrderIds(address).call();
                Promise.all(data_array.map(async (index) => {
                    const pendingReward = await stakecontract.methods.pendingRewards(index).call();
                    const object = {
                        pendingReward: pendingReward / Math.pow(10, DECIMALS)
                    }
                    return object;
                })).then((result) => {
                    setRewardcal(result);
                    setCurrentTime(Math.floor(Date.now() / 1000));
                })
            } catch (err) {
                console.log(err);
            }
        }

        if (address) {
            getOrder();
            rewardCount();
            setInterval(() => {
                rewardCount();
            }, 60000);
        }
        // eslint-disable-next-line
    }, [address]);

    return (
        <div className='row mb-4'>
            <div className='col-12 col-xl-8'>
                <TabButton setTab={setTab} tab={tab} />
                <div className='table-responsive'>
                    <table class="table table-bordered border-primary table-unstake">
                        <thead>
                            <tr>
                                <th scope="col">Serial</th>
                                <th scope="col">Token Amount</th>
                                <th scope="col">Pending Rewards</th>
                                <th scope="col">Staking Date</th>
                                <th scope="col">Time Left</th>
                                <th scope="col" colSpan={2}>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {orders.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{parseInt(index + 1)}</td>
                                        <td>{formatPrice(row.amount)}</td>
                                        <td>{formatPrice(rewardcal[index] ?  rewardcal[index].pendingReward : 'waiting..')}</td>
                                        <td>{new Date(row.starttime * 1000).toString().substring(4, 25)}</td>
                                        <td>{Math.round(Math.abs((row.endtime - row.starttime) / 86400))} Days</td>
                                        <td>
                                            <button onClick={() => row.claimed === 'false' ? handleClaim(index, row.orderId) : ''} disabled={(row.endtime <= currentTime) ? false : true} type="button" className="theme-btn1">
                                                {cLoading[0] === index && cLoading[1] ? 'Loading...' : 'Withdraw'}
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => row.claimed === 'false' ? handleTerminate(index, row.orderId) : ''} disabled={(row.endtime > currentTime) ? false : true} type="button" className="theme-btn1">
                                                {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Emergency Withdraw'}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>

    )
}
