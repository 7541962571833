import { bsc } from "wagmi/chains";


export const contract = {
  97: {
    STAKE_ADDRESS: "0x88a7fa3ed82d2e13ac54aaf3518aaad378f9f1e2",
    MULTICALL_ADDRESS: "0xa54fE4a3DbD1Eb21524Cd73754666b7E13b4cB18",
    TOKEN_ADDRESS: "0xc78c66289d79df009660a9789e3b025933984971",
  },
  56: {
    STAKE_ADDRESS: "0x1601a67f721f3368ed6c9407c8952b910612efa3",
    MULTICALL_ADDRESS: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    TOKEN_ADDRESS: "0xCEF81e71e9fE10e7FEEAaF742AeC9B855dC29A5B",
  }
}

export const DEFAULT_CHAIN = 56;
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s1.binance.org:8545/"
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DECIMALS = 18;
export const SYMBOL = 'KNB';
export const EXPLOER = 'https://bscscan.com/';
export const chains = [bsc]



export const daysTab = [
  {
    id: 1,
    content: "15 Days",
    apy: "5",
    timestamp: "15"
  },
  {
    id: 2,
    content: "30 Days",
    apy: "10",
    timestamp: "30"
  },
  {
    id: 3,
    content: "45 Days",
    apy: "15",
    timestamp: "45"
  },
  {
    id: 4,
    content: "60 Days",
    apy: "20",
    timestamp: "60"

  }
];






