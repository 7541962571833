import React, { useState } from 'react'
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import TopCards from '../components/TopCards';
import Stake from '../components/Stake';
import { useCommonStats } from '../hooks/useBscCommon';
import { useAccountStats } from '../hooks/useBscAccount';
import Unstake from '../components/Unstake';


export default function Home() {
    const [updater, setUpdater] = useState(1);
    const commonStats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [tab, setTab] = useState(1);

    return (
        <>
            <Sidebar />
            <div className="content">
                <Header />
                <div className='main-content'>

                    <TopCards commonStats={commonStats} />
                    <div className='row mt-4'>
                        <div className='col-sm-12 col-xl-12 col-lg-12'>
                            <div className='card'>
                                
                                <div className='card-body'>
                                    <div class="tab-content" id="nav-tabContent">
                                        {tab === 1 &&
                                            <Stake setTab={setTab} tab={tab} commonStats={commonStats} accStats={accStats} setUpdater={setUpdater} />
                                        }
                                        {tab === 2 &&
                                            <Unstake updater={updater} setTab={setTab} tab={tab} accStats={accStats} commonStats={commonStats} setUpdater={setUpdater} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}
