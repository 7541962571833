import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { toast } from "react-toastify";
import { DECIMALS, DEFAULT_CHAIN, contract } from "../helper/constant";
import { getMultiCall } from "../helper/contractHelper";
import { useAccount } from "wagmi";
import { getWeb3 } from "../helper/functions";



export const useAccountStats = (updater, pageId = 1) => {
    let { address, isConnected } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        balance: 0,
        bnbBalance: 0,
        isApprove: 0,
        totalStake: 0,
        orderIds: [],
        totalRewardEarn: 0
    });


    let sc = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
    let tc = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS);


    useEffect(() => {
        const fetch = async () => {
            try {
                const bnbBalance = await web3.eth.getBalance(address).then(balance => web3.utils.fromWei(balance, 'ether'));
                const data = await getMultiCall([
                    tc.methods.balanceOf(address),
                    tc.methods.allowance(address, contract[DEFAULT_CHAIN].STAKE_ADDRESS),
                    sc.methods.balanceOf(address),
                    sc.methods.investorOrderIds(address),
                    sc.methods.totalRewardEarn(address),
                ]);

                console.log(parseFloat(data[1]) / Math.pow(10, 18))


                setStats({
                    bnbBalance,
                    balance: data[0] / Math.pow(10, DECIMALS),
                    isApprove: data[1] / Math.pow(10, DECIMALS),
                    totalStake: data[2] / Math.pow(10, DECIMALS),
                    orderIds: data[3],
                    totalRewardEarn: data[4] / Math.pow(10, DECIMALS)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address && isConnected) {
            fetch();
        }
        else {
            setStats({
                balance: 0,
                bnbBalance: 0,
                isApprove: 0,
                totalStake: 0,
                orderIds: [],
                totalRewardEarn: 0
            })
        }
        // eslint-disable-next-line
    }, [updater, address, pageId]);

    return stats;
}







