import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import { toast } from "react-toastify";
import { DEFAULT_CHAIN, contract } from "../helper/constant";
import { getMultiCall } from "../helper/contractHelper";
import { getWeb3 } from "../helper/functions";




export const useCommonStats = (updater) => {

    let web3 = getWeb3();



    const [stats, setStats] = useState({
        totalRewardsDistribution: 0,
        totalStake: 0,
        totalWithdrawal: 0
    });

    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    stakeContract.methods.totalRewardsDistribution(),
                    stakeContract.methods.totalStake(),
                    stakeContract.methods.totalWithdrawal()
                ]);


                setStats({
                    totalRewardsDistribution: data[0] / Math.pow(10, 18),
                    totalStake: data[1] / Math.pow(10, 18),
                    totalWithdrawal: data[2] / Math.pow(10, 18)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}
