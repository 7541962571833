import React, { useMemo } from 'react'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { formatPrice } from '../helper/functions';
import { SYMBOL } from '../helper/constant';

export default function TopCards({ commonStats }) {
    const maxAPY = 0

    

    return (
        <div className='row mt-4'>
            <div className='col-sm-12 col-md-6 col-lg-6'>
                <div className='box-card'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <AccountBalanceIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>Total Staked</h6>
                                <p>{formatPrice(commonStats.totalStake - commonStats.totalWithdrawal)} {SYMBOL}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
                <div className='box-card'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <SavingsIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>Current APY</h6>
                                <p>UPTO {formatPrice(maxAPY / 100)}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
