import React from 'react';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import ConnectButton from './ConnectButton';
import logoImg from '../img/logo.png';

export default function Header() {
    return (
        <div className="header">
            <div className="d-flex justify-content-between">

                <div className='d-flex'>
                    <div className="menu-bt-sidebar toggle-sidebar-btn desk-none me-2">
                        <MenuOpenOutlinedIcon sx={{ color: "white" }} fontSize='large' />
                    </div>
                    <img className='d-block d-md-none' src={logoImg} alt='logo' height="40px" width="40px"  />
                </div>
                <ConnectButton/>
            </div>
        </div>
    )
}
