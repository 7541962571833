import Web3 from "web3";
import { DEFAULT_RPC } from "./constant";
import moment from "moment";


export const trimAddress = (addr) => {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}


export const getWeb3 = () => {
    return new Web3(DEFAULT_RPC);
}

export const formatDateWithZone = (unixTime) => {
    try {
        let dateString = new Date(`${unixTime} UTC`).toString();
        let startIndex = dateString.indexOf("GMT");
        let modifyDate = dateString.substring(0, startIndex);
        return modifyDate;
    }
    catch (err) {
        console.log(err.message);
        return '';
    }
}

export const shorterDate = (unixTime = 0) => {
    return moment(unixTime).format("MMM Do YY"); 
}


export const formatDate = (unixTime, onlydate = false) => {
    try {
      if (onlydate) {
        let dateString = moment(unixTime).format("LL"); 
        return dateString;
      }
      else {
        let dateString = moment(unixTime).format('lll');
        return dateString;
      }
    }
    catch (err) {
      console.log(err.message);
    }
  
  }


export const formatPrice = (num, decimals = 8) => {
    if (num >= 1) {
        return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(num);
    }
    else {
        return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4 }).format(num);
    }
}